import Vuex from "vuex";
import Vue from "vue";

import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import chat from "./modules/chat";
import config from "./modules/config";
import invoice from "./modules/invoice";
import cart from "./modules/cart";
import verticalSidebar from "./modules/verticalSidebar";
import scrumboard from "./modules/scrumboard";

// Load Vuex
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    chat,
    config,
    invoice,
    cart,
    verticalSidebar,
    scrumboard
  },
  strict: debug
});
