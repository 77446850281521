<template>
      <b-card>
        <b-card-title>
          <span class="headline">{{ message }}</span>
        </b-card-title>
        <b-button color="blue darken-1" flat @click="emit_no">{{ no }}</b-button>
        <b-button color="red" flat @click="emit_yes">{{ yes }}</b-button>

      </b-card>
</template>

<script>
  export default {
      data: () => ({
          dialog: false,
      }),
      methods: {
        emit_no() {
          this.$emit("no");
        },
        emit_yes() {
          this.$emit("yes");
        }
      },
      props: {
        message: {
          type: String,
          default: "Are you sure?"
        },
        title: {
          type: String,
          default: "Are you sure?"
        },
        yes: {
          type: String,
          default: "Yes"
        },
        no: {
          type: String,
          default: "No"
        }
      }
  }
</script>
