import VueRouter from "vue-router"

import store from "./store";
// import {isMobile} from "mobile-device-detect";
import NProgress from "nprogress";

import { authGuard } from "./auth/guard";

function loadView(view) {
  return () => import(`./views/app/${view}.vue`);
}

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open",
  routes: [
    {
      path: "/",
      component: () => import("./views/app"), //webpackChunkName app
      beforeEnter: authGuard,
      redirect: "/home",
      children: [
        {
          path: "/home",
          component: () => import("./views/app/dashboard/home")
        },
        {
          path: "/doorzoek",
          component: () => import("./views/app/dashboard/doorzoek"),
          redirect: "/doorzoek/doorzoek",
          children: [
            {
              path: "doorzoek",
              component: () => import("./views/app/dashboard/doorzoek/doorzoek"),
            }
          ]
        },
        {
          path: "/boeking",
          component: () => import("./views/app/dashboard/boeking"),
          redirect: "/boeking/reserveer",
          children: [
            {
              path: "reserveer",
              component: () => import("./views/app/dashboard/boeking/reserveer"),
            },
            {
              path: "reserveringen",
              component: () => import("./views/app/dashboard/boeking/reserveringen"),
            }
          ]
        },
        {
          path: "/binnenklimaat",
          component: () => import("./views/app/dashboard/binnenklimaat"),
          redirect: "/binnenklimaat/sensoren",
          children: [ 
            {
              path: "sensoren",
              component: () => import("./views/app/dashboard/binnenklimaat/sensoren"),
            },
            {
              path: "feedback",
              component: () => import("./views/app/dashboard/binnenklimaat/feedback"),
            }
          ]
        },
        {
          path: "/profile",
          component: () => import("./views/app/dashboard/profile/profile")
        },
        {
          path: "/admin",
          component: () => import("./views/app/dashboard/binnenklimaat"),
          redirect: "/admin/reserveringen",
          meta: {
              role : ["admin"]
          },
          children: [ 
            {
              path: "feedback",
              component: () => import("./views/app/dashboard/admin/feedback"),
            },
            {
              path: "reserveringen",
              component: () => import("./views/app/dashboard/admin/reserveringen"),
            },
            {
              path: "werkplekken",
              component: () => import("./views/app/dashboard/admin/werkplekken"),
              
            }
          ]
        }
      ]
    },
    {
      path: "/error",
      component: () => import("./views/app/pages/error")
    },
    {
      path: "*",
      component: () => import("./views/app/pages/notFound")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  }
});

router.beforeEach((to, from, next) => {
  console.log(to);

  // If this isn't an initial page load.
  if (to.path) {
    NProgress.start();
    NProgress.set(0.1);
  }

  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500);
  // NProgress.done();
  // if (isMobile) {
  if (window.innerWidth <= 1200) {
    // console.log("mobile");
    store.dispatch("changeSidebarProperties");
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
      store.dispatch("changeCompactSidebarProperties");
    }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  }
});

export default router;
