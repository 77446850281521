// @ts-nocheck
import 'es6-promise/auto';

import Vue from "vue";
import VueRouter from 'vue-router'

import store from "./store";
import router from "./router";

import config from "./store/modules/config";

import '@axios'
import i18n from '@/libs/i18n'

import App from "./App.vue";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueRouter);

import plugins from "./plugins/index";
Vue.use(plugins);

const app = new Vue({
  store,
  router,
  config,
  i18n,
  render: h => h(App)
});

// Pusher.logToConsole = true;

// var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
//   cluster: process.env.VUE_APP_PUSHER_CLUSTER
// });

// var channel = pusher.subscribe(process.env.VUE_APP_PUSHER_CHANNEL);
// channel.bind('my-event', function(data) {
//   app.messages.push(JSON.stringify(data));
// });

app.$mount("#app");
