import Vue from 'vue'

// axios
import axios from 'axios'
import ToastificationContent from '@c/ToastificationContent'

// import router from '@router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  switch(error.response.status) {
    case 404: {
      console.log(error);
    }
    case 503: {
      console.log(process.env);
      window.location = "https://"+process.env.VUE_APP_DOMAIN+":"+process.env.VUE_APP_PORT+"/maintenance";
    }
  }

  console.log(error.response);
  switch(error.response.data.status) {
    case "mfa" :{
      window.location = error.response.data.url;
    }
  }

  if(error.response && error.response.data.errors) {
    Vue.$toast.error('Er is een fout opgetreden');
    return;
  }
  if(error.response && error.response.data.error) {
    Vue.$toast.error('Er is een fout opgetreden');
    return;
  }
  if (error.response && error.response.data.message) {
    Vue.$toast.error('Er is een fout opgetreden');
    return;
  } 
  Vue.$toast.error('Er is een fout opgetreden');
  return Promise.reject(error);
});

if(!Vue.prototype.$http) {
  Vue.prototype.$http = axiosIns
}

export default axiosIns
