// import vuePerfectScrollbar from "vue-perfect-scrollbar";
import VueTour from "vue-tour";
// import SmartTable from "vuejs-smart-table";
import VueTagsInput from "@johmun/vue-tags-input";
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
// import VueFormWizard from "vue-form-wizard";
import VueLazyload from "vue-lazyload";
// import VCalendar from "v-calendar";

import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import Meta from "vue-meta";
import FlagIcon from "vue-flag-icon";

import { BootstrapVue, BootstrapVueIcons, BIcon, BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import Breadcumb from "../components/breadcumb";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import AreYouSure from '@c/AreYouSure';

import VueEllipseProgress from 'vue-ellipse-progress';

import "nprogress/nprogress.css";

import "@a/styles/sass/app.scss";
import "@a/styles/sass/themes/lite-blue.scss"

import "@p/echarts";

import LoaderComponent from "@c/LoaderComponent"

import Verte from 'verte';
import 'verte/dist/verte.css';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import FeatherIcon from '@c/FeatherIcon'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


import VueTypeaheadBootstrap from 'vue-bootstrap-typeahead'
import 'vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.css'

export default {
  install(Vue) {
    Vue.use(BootstrapVue);
    Vue.component("LoaderComponent", LoaderComponent);
    Vue.component(
      "large-sidebar",
      // The `import` function returns a Promise.
      () => import("../containers/layouts/largeSidebar")
    );
    Vue.component("Verte", Verte);
    Vue.component(
      "customizer",
      // The `import` function returns a Promise.
      () => import("../components/common/customizer.vue")
    );
    Vue.component("vue-perfect-scrollbar", () =>
      import("vue-perfect-scrollbar")
    );

    Vue.use(VueToast);

    Vue.component("FeatherIcon", FeatherIcon);
    
    Vue.component("breadcumb", Breadcumb);

    Vue.component("multiselect", Multiselect);

    Vue.component("VueSlider", VueSlider);

    // Vue.component(VueCropper);
    Vue.use(Meta, {
      keyName: "metaInfo",
      attribute: "data-vue-meta",
      ssrAttribute: "data-vue-meta-server-rendered",
      tagIDKeyName: "vmid",
      refreshOnceOnNavigation: true
    });

    Vue.component('v-select', vSelect)

    Vue.use(FlagIcon);

    //vuelidate
    Vue.use(Vuelidate);
    // maskForm
    Vue.use(VueTheMask);
    //form wizard globally

    Vue.use(VueEllipseProgress);

    Vue.component("AreYouSure", AreYouSure);

    Vue.component("VueTypeaheadBootstrap", VueTypeaheadBootstrap);

    // Vue.use(VueFormWizard);

    // tags input plugin
    Vue.use(VueTagsInput);

    Vue.use(BootstrapVueIcons);
    Vue.component('BIcon', BIcon);
    Vue.component('BIconArrowUp', BIconArrowUp);
    Vue.component('BIconArrowDown', BIconArrowDown);


    // smart table plugin
    // Vue.use(SmartTable);

    // vue-good-table

    // import the styles

    Vue.use(VueGoodTablePlugin);

    // import VueCropper from "./plugins/imageCropper";

    // Use v-calendar & v-date-picker components
    // Vue.use(VCalendar, {
    //   componentPrefix: "v" // Use <vc-calendar /> instead of <v-calendar />
    //   // ...other defaults
    // });

    Vue.use(VueTour);
    Vue.use(VueLazyload, {
      observer: true,
      // optional
      observerOptions: {
        rootMargin: "0px",
        threshold: 0.1
      }
    });
  }
};
