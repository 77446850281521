import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Load all locales and remember context
function loadMessages() {
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );

  return { context, messages };
}

const { context, messages } = loadMessages();

export default new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages
})
