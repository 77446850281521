import { getInstance } from "./index";

import Vue from "vue"
import axios from "@axios"

import Store from "@/store"

export const authGuard = (to, from, next) => {
    const authService = getInstance();
    const fn = () => {
      // If the user is authenticated, continue with the route
      if (authService.isAuthenticated) {
        if(!authService.db_loading && !to.meta.db_request) {
          authService.db_loading = true;
          authService.getTokenSilently().then(function(token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
              
            Vue.prototype.$http.get("/user").then((response) => {
              if(response) {
                authService.db = response.data.user;
                console.log("---")
                console.log(Store);
                Store.commit("setThemeColors", response.data.user.organization.colors);
                authService.db_loading = false;
              }
            }).catch((error) => {
              console.log(error);
              authService.db_loading = false;
              if(error.response.data.status !== "mfa") {
                authService.logout();
              }
            });
          });
        }
        return next();
      }

      // Otherwise, log in
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
      if (loading === false) {
        return fn();
      }
    });
};
